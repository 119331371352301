@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'react-datepicker/dist/react-datepicker.css';
@import 'handsontable/dist/handsontable.full.css';

body {
  background-color: #f7f7f7;
  overflow-x: hidden;
}

.loader {
  border-top-color: #565ef0;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Dropdown */

.dropdown-menu-hidden {
  display: none;
}

.dropdown-menu-visible {
  display: block;
}

/* Tooltip */

.tooltip {
  visibility: hidden;
  position: absolute;
}

.has-tooltip:hover .tooltip {
  visibility: visible;
  z-index: 100;
}

input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #565ef0;
}

.step-line {
  width: calc(100% - 2.5rem - 1rem);
  top: 50%;
  transform: translate(-50%, -50%);
}

.appearance-none::-webkit-inner-spin-button,
.appearance-none::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* DateRangePicker */
.react-daterange-picker .react-daterange-picker__wrapper {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-bottom: 0.45rem;
  padding-top: 0.45rem;
  border: 1px solid rgba(209, 213, 219, 0.75);
  border-radius: 0.25rem;
}
.react-daterange-picker__wrapper input,
.react-daterange-picker__wrapper span {
  font-size: 0.75rem;
  line-height: 1rem;
}
.react-daterange-picker__wrapper .react-daterange-picker__inputGroup {
  text-align: center;
}
.react-datepicker__day.react-datepicker__day--keyboard-selected {
  background-color: #fff;
  color: #000;
}
.react-datepicker__day.react-datepicker__day--in-selecting-range,
.react-datepicker__day.react-datepicker__day--in-range,
.react-datepicker__day.react-datepicker__day--in-range:hover {
  background-color: rgba(86, 94, 240, 0.8);
  color: rgba(255, 255, 255, 0.8);
}
.react-datepicker__day.react-datepicker__day--selected,
.react-datepicker__day.react-datepicker__day--range-start,
.react-datepicker__day.react-datepicker__day--range-end,
.react-datepicker__day.react-datepicker__day--range-start:hover,
.react-datepicker__day.react-datepicker__day--range-end:hover,
.react-datepicker__day.react-datepicker__day--selected:hover {
  background-color: rgb(86, 94, 240);
  color: white;
}

.react-datepicker__day.react-datepicker__day--today,
.react-datepicker__day.react-datepicker__day--today:focus {
  background-color: rgb(39, 48, 210);
  color: white;
}

.document-details-panel {
  flex-flow: row nowrap;
}

.document-details-attachment {
  min-width: 650px;
}

.document-container {
  width: fit-content;
}

[role='columnheader'] > div {
  overflow: visible !important;
  white-space: normal !important;
  text-overflow: initial !important;
}
[role='gridcell'] {
  width: 100% !important;
  min-width: 50px !important;
}

[role='gridcell'] > div {
  text-overflow: initial !important;
  overflow: visible !important;
  white-space: normal !important;
  word-break: normal;
}
